@font-face {
  font-family: "Meme";   /*Can be any text*/
  src: local("Impact"),
    url("./fonts/impact.ttf") format("truetype");
}

@font-face {
  font-family: "Londrina";   /*Can be any text*/
  src: local("LondrinaSolid"),
    url("./fonts/LondrinaSolid-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Light";   /*Can be any text*/
  src: local("LondrinaSolid-Light"),
    url("./fonts/LondrinaSolid-Light.ttf") format("truetype");
}

body {
  margin: 0;
}

.text-highlight {
  font-style: normal;
  font-size: larger;
  color:#fff;
  font-family: "Londrina";
  border-radius: 1em 0 1em 0;
  // text-shadow: 2px 2px 2px #000;
  text-shadow:1px 1px 0 #000,
  -1px -1px 0 #000,
  1px -1px 0 #000,
  -1px 1px 0 #000,
  0px 1px 0 #000,
  1px 0px 0 #000,
  0px -1px 0 #000,
  -1px 0px 0 #000;
}

.text-highlight-white {
  font-style: normal;
  border-radius: 1em 0 1em 0;
  text-shadow: 1px 1px 1px #fff;
  background-color: rgba(#fff, 0.8)
}

.text-meme{
  text-shadow:2px 2px 0 #000,
  -2px -2px 0 #000,
  2px -2px 0 #000,
  -2px 2px 0 #000,
  0px 2px 0 #000,
  2px 0px 0 #000,
  0px -2px 0 #000,
  -2px 0px 0 #000;
}

.text-meme-main {
  text-shadow:3px 3px 0 #000,
  -3px -3px 0 #000,
  3px -3px 0 #000,
  -3px 3px 0 #000,
  0px 3px 0 #000,
  3px 0px 0 #000,
  0px -3px 0 #000,
  -3px 0px 0 #000;
  animation: tilt-n-move-shaking-1 1.3s infinite ease-in-out;
}

.text-meme-menu {
  text-shadow:3px 3px 0 #000,
  -3px -3px 0 #000,
  3px -3px 0 #000,
  -3px 3px 0 #000,
  0px 3px 0 #000,
  3px 0px 0 #000,
  0px -3px 0 #000,
  -3px 0px 0 #000;
  animation: tilt-n-move-shaking-1 1.3s infinite ease-in-out;
}

@media (min-width: 0px) {
  // Custom styles for extra-small screens and up
  .text-meme-main, .text-meme-alt {
    font-size: 4rem!important;
  }

  #animatedLogo, #animatedLogo img{
    height: 250px;
    width: 250px;
  } 

  .socialIcon {
    height: 60px!important;
    width: 60px!important;
  }
}

@media (min-width: 600px) {
  // Custom styles for small screens and up
  .text-meme-main, .text-meme-alt {
    font-size: 5rem!important;
  }

  #animatedLogo, #animatedLogo img{
    height: 300px;
    width: 300px;
  } 

  .socialIcon {
    height: 75px!important;
    width: 75px!important;
  }
}

@media (min-width: 960px) {
  // Custom styles for medium screens and up
  .text-meme-main, .text-meme-alt {
    font-size: 6rem!important;
  }

  #animatedLogo, #animatedLogo img{
    height: 350px;
    width: 350px;
  } 

  .socialIcon {
    height: 75px!important;
    width: 75px!important;
  }
}

@media (min-width: 1280px) {
  // Custom styles for medium screens and up
  .text-meme-main, .text-meme-alt {
    font-size: 9rem!important;
  }

  #animatedLogo, #animatedLogo img{
    height: 450px;
    width: 450px;
  } 

  .socialIcon {
    height: 80px!important;
    width: 80px!important;
  }
}

@media (min-width: 1920px) {
  // Custom styles for extra-large screens and up
  .text-meme-main, .text-meme-alt {
    font-size: 9rem!important;
  }

  #animatedLogo, #animatedLogo img{
    height: 500px;
    width: 500px;
  } 

  .socialIcon {
    height: 90px!important;
    width: 90px!important;
  }
}

@media (min-width: 2560px) {
  // Custom styles for 2K screens and more
  .text-meme-main, .text-meme-alt {
    font-size: 12rem!important;
  }

  #animatedLogo, #animatedLogo img{
    height: 700px;
    width: 700px;
  } 

  .socialIcon {
    height: 100px!important;
    width: 100px!important;
  }
}

@media (min-width: 3840px) {
  // Custom styles for 4K screens and more
  .text-meme-main, .text-meme-alt {
    font-size: 14rem!important;
  }

  #animatedLogo, #animatedLogo img{
    height: 850px;
    width: 850px;
  } 

  .socialIcon {
    height: 100px!important;
    width: 100px!important;
  }
}


.text-meme-alt {
  text-shadow:3px 3px 0 #000,
  -3px -3px 0 #000,
  3px -3px 0 #000,
  -3px 3px 0 #000,
  0px 3px 0 #000,
  3px 0px 0 #000,
  0px -3px 0 #000,
  -3px 0px 0 #000;
  animation: tilt-n-move-shaking-2 1.3s infinite ease-in-out;
}

@keyframes tilt-n-move-shaking-2 {
  0% { transform: translate(0, 0) rotate(0deg); }
  12% { transform: translate(-8px, 5px) rotate(-2deg); }
  25% { transform: translate(-7px, 3deg) rotate(-3deg); }
  30% { transform: translate(-5px, 2px) rotate(-5deg); }
  50% { transform: translate(0, 0) rotate(0eg); }
  70% { transform: translate(8px, 2px) rotate(3deg); }
  80% { transform: translate(10px, 5px) rotate(5deg); }
  90% { transform: translate(6px, 2.5px) rotate(3deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}

@keyframes tilt-n-move-shaking-1 {
  0% { transform: translate(0, 0) rotate(0deg); }
  12% { transform: translate(6px, 2.5px) rotate(3deg); }
  25% { transform: translate(10px, 5px) rotate(5deg); }
  30% { transform: translate(8px, 2px) rotate(3deg); }
  50% { transform: translate(0, 0) rotate(0eg); }
  70% { transform: translate(-5px, 2px) rotate(-5deg); }
  80% { transform: translate(-7px, 3deg) rotate(-3deg); }
  90% { transform: translate(-8px, 5px) rotate(-2deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}


.text-meme-light{
  text-shadow:2px 2px 0 #fff,
  -2px -2px 0 #fff,
  2px -2px 0 #fff,
  -2px 2px 0 #fff,
  0px 2px 0 #fff,
  2px 0px 0 #fff,
  0px -2px 0 #fff,
  -2px 0px 0 #fff;
}

h2{
  word-wrap: break-word;
  font-size: 4rem;
}

.rounded-button{
  border-radius: 5px!important;
}

.rounded-button-outlined{
  color: #fff!important;
  background-color: #d18951!important;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.tokenomicsBox {
  padding: 3em 1em 3em 1em;
  border-radius: 5px;
  width:auto;
}

.RoadmapBox{
  padding: 3em 1em 3em 1em;
  border-radius: 5px;
  width:auto;
  padding-left: 3rem;
  background-color: rgba(0, 0, 0, 0.08)!important;
  .MuiStepContent-root, .MuiStepConnector-line  {
    border-color: white;
  }

  .MuiSvgIcon-root{
    color: white!important;
    width: 1.5em!important;
    height: 1.5em!important;
    border: 1px solid #000;
    border-radius: 50%;
  }

  .MuiButtonBase-root{
    color: white;
  }


}

.main-title{
  padding-bottom: 3rem;
  word-wrap: break-word;
  font-size: 5rem!important;
}

#socials{
  padding-left: 0;
}

#BaseLine{
  padding: 1rem;
}

.title-section{
  font-size: 4.5rem!important;
}